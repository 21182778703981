import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { setContext } from '../../helpers/gql';
import { testMobile } from '../../helpers/common';
import styles from './calculator.module.scss';
import { lng } from '../../config/language';
import CalculatorNotification from './calculator-notification';
import CalculatorModalDecision from './calculator-modal-decision';
import CalculatorSteps from './calculator-steps';
import CalculatorBottom from './calculator-bottom';
import { useStore as calculatorStore } from '../../contexts/calculator';
import request from '../../requests/mutation/calculator-submit';
import useGa from '../_hooks/useGa';
import useCalculator from '../_hooks/useCalculator';
import useAnalytics from '../_hooks/useAnalytics';

const Calculator = () => {
	const generateRequestId = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
	const [requestId, setRequestId] = useState(generateRequestId());
	const { fbEvent } = useAnalytics();

	const [mounted, setMounted] = useState(false);
	const [stepWidth, setStepWidth] = useState(0);
	const [isMobile] = useState(testMobile());
	const { data: calculatorData, dispatch } = calculatorStore();
	const { data, instanceId, step, notification } = calculatorData;
	const [gqlMutation, { loading: requestLoading, data: requestData, error: requestError }] = useMutation(request.gql.schema);
	const { sendEvent } = useGa();
	const { isBSCandidate, catchRequest } = useCalculator();

	const refElem = useRef(0); // to calculate step width

	const onMounted = () => {
		setMounted(true);
	};

	const setNotification = (payload) => {
		if (notification.visible === false) {
			dispatch({
				type: 'SET_NOTIFICATION',
				payload: { visible: true, ...payload }
			});
		}
	};

	const getData = (d) => {
		return { ...data, ...d, request_id: requestId, source: 'izracun-kredita.si' };
	};

	const sendInquiry = (d) => {
		// Lock submit / disable
		dispatch({ type: 'TOGGLE_SUBMIT_LOCK' });

		// Send data to server
		sendEvent(null, null, `SUBMIT: START`, JSON.stringify({ ...data, ...d, source: 'izracun-kredita.si' }));

		gqlMutation({
			variables: getData(d), // combine all saved data till now and from last form
			context: setContext('send_mail'),
			errorPolicy: 'all'
		});

		// Send event to fb
		fbEvent('Lead', { content_name: 'izracun-kredita.si' });

		dispatch({ type: 'RESET_SUBMIT' });
	};

	const onStepSuccess = (s, d) => {
		sendEvent(null, null, `NEXT STEP [${s}] / SUBMIT CLICKED`, JSON.stringify(data));

		// Scroll back top
		setTimeout(() => {
			window.scrollTo(0, isMobile === false ? 0 : 400); // 400 = mobile css style for index.module.scss/.calculator
		}, 400);

		dispatch({ type: 'UPDATE_DATA', payload: { data: d } });
		dispatch({ type: 'SET_SUBMIT_BUTTON', payload: { text: lng('btnNext'), icon: 'arrow-right-white' } });

		switch (step) {
		case 2:
			// Set new request id
			setRequestId(generateRequestId());
			dispatch({ type: 'STEP_NEXT' });
			break;

		case 3:
			if (!isBSCandidate({ ...data, ...d })) {
				// Send request to catch request in case, user close app before submit (from modal)
				catchRequest(getData(d));

				// Show modal
				dispatch({
					type: 'MODAL', // decision modal BS
					payload: {
						visible: true,
						onConfirm: dta => sendInquiry(dta),
						onCancel: () => sendInquiry(d) // not candidate at all! not possible to modify values, to fit
					}
				});
			} else {
				sendInquiry(d); // BS candidate = yes -> submit
			}
			break;

		default:
			dispatch({ type: 'STEP_NEXT' });
			break;
		}
	};

	useEffect(() => {
		dispatch({ type: 'RESET_SUBMIT_BUTTON' });

		// Calculate step width
		setStepWidth(refElem.current.getBoundingClientRect().width - 50);

		onMounted();

		// Scroll down to calculator
		/*
		if (isMobile && paramsViaURL !== null) {
			window.scrollTo(0, 400);
		}
		*/
	}, []);

	// Show notification, after server response
	useEffect(() => {
		if (requestData && requestLoading === false) {
			// Handle server request - error or success notification
			if (requestError) {
				sendEvent(null, null, `SUBMIT: END - ERROR`, requestError.graphQLErrors[0].potError.message);

				setNotification({
					type: 'error',
					title: 'Napaka :/',
					text: requestError.graphQLErrors[0].potError.message,
					redoBtn: {
						icon: null,
						text: lng('btnRepeat')
					}
				});
			} else {
				sendEvent(null, null, `SUBMIT: END - SUCCESS`);

				setNotification({
					type: 'ok',
					title: lng('notificationSuccessTitle'),
					text: lng('notificationSuccessText'),
					showData: true
					/*
					redoBtn: { // izključeno 20.12.2021 - gumb: Ponovi na zahvalnem screenu
						icon: null,
						text: lng('btnRepeat')
					}
					*/
				});
			}
		}
	}, [requestData, requestLoading, requestError]);

	return (
		<div className={styles.base} ref={refElem}>
			<CalculatorNotification variation="desktop" />
			<CalculatorModalDecision onStepSuccess={onStepSuccess} />
			{mounted === false && <strong className={styles.initializing}>{lng('labelInit')}</strong>}
			<CalculatorSteps onStepSuccess={onStepSuccess} onMounted={onMounted} stepWidth={stepWidth} key={instanceId} />
			{mounted === true ? <CalculatorBottom variation="desktop" /> : null}
		</div>
	);
};

export default Calculator;
