import { lng } from '../../config/language';

const request = {
	validation: {

		givenname: {
			required: true,
			validator: null
			/*
			validator: {
				regEx: /^[a-zA-Z0-9]+$/,
				error: lng('validationGivenname')
			}
			*/
		},

		surname: {
			required: true,
			validator: null
		},

		email: {
			required: true,
			validator: {
				// regEx: /^(?!\.)(?!.*\.\.)[a-zA-Z0-9!#$%&‘.*+/=?^_`{|}~-]+(?<!\.)@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
				regEx: /^(?!\.)(?!.*\.\.)[a-zA-Z0-9!#$%&‘.*+/=?^_`{|}~-]+[a-zA-Z0-9]@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/,
				error: lng('validationEmail')
			}
		},

		telephone: {
			required: true,
			validator: null
		},

		/*
		agreement_terms: {
			required: true,
			validator: null
		},
		*/

		agreement_terms1: {
			required: true,
			validator: null
		},

		agreement_terms2: {
			required: true,
			validator: null
		}
	},

	gql: {
		schema: null
	}
};

export default request;
