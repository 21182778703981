import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Button = ({ children, type, classes, size, onClick, disabled, ariaLabel }) => {
	return <button className={`btn ${classes} ${size}`} type={type} onClick={onClick} disabled={disabled} aria-label={ariaLabel || 'cta'}>{children}</button>;
};

Button.defaultProps = {
	type: 'button',
	classes: 'primary',
	size: '',
	onClick: null,
	disabled: false,
	ariaLabel: null
};

Button.propTypes = {
	children: PropTypes.node.isRequired,
	type: PropTypes.string,
	classes: PropTypes.string,
	size: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	ariaLabel: PropTypes.string
};

export default Button;
