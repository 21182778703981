import React from 'react';
import PropTypes from 'prop-types';

import styles from './calculator.module.scss';
import { lng } from '../../config/language';
import Button from '../form/button';
import { useStore as calculatorStore } from '../../contexts/calculator';

const CalculatorBottom = ({ scrollY, variation }) => {
	const { data: calculatorData, dispatch } = calculatorStore();
	const { steps, step, monthlyInstallment, submitBtn, submitLocked } = calculatorData;

	return (
		<div className={`${styles.calculatorBottom} ${styles[variation]}`}>
			<div className={`${styles.stepButton} ${styles[scrollY > 0 || step > 1 ? 'scrolled' : 'default']}`}>
				<div className={styles.progress}><span style={{ width: `${((100 * (step - 1)) / steps.length)}%` }} /></div>
				<div className={styles.amountBottom}><span>{lng('calcCreditBottomTitle')}</span><strong>{monthlyInstallment} €/m</strong></div>
				<Button ariaLabel="nadaljuj" classes={[styles.calculatorSubmit, 'primary', submitBtn.icon ? 'icon right' : 'default', submitLocked ? 'disabled' : ''].join(' ')} disabled={submitLocked} onClick={() => dispatch({ type: 'SUBMIT_STEP' })}>{submitBtn.text} {submitBtn.icon ? <i className={`icon ${submitBtn.icon}`} /> : null}</Button>
			</div>
			<div className={styles.dots}>
				{steps.map((row, index) => <span key={row} className={(index + 1) === step ? styles.selected : ''} />)}
			</div>
		</div>
	);
};

CalculatorBottom.defaultProps = {
	scrollY: 0
};

CalculatorBottom.propTypes = {
	scrollY: PropTypes.number,
	variation: PropTypes.string.isRequired
};

export default CalculatorBottom;
