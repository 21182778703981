import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';

import useForm from '../_hooks/useForm';
import { scrollToFirstError } from '../../helpers/form';
import { gqlValidation, setContext } from '../../helpers/gql';

import AsyncComponent from '../_hoc/AsyncComponent';

/*
HOW TO:
- create src/forms/<name>.js (settings config: validation, gql schema, ...)
- FormLoader>getFormSettings add case by name
- FormLoader>loadFormQuery add case by name (in case query is needed, to prefill form - ex. on edit)
*/

const FormLoader = React.memo(({ name, id, initialState, placeholders, onSuccess }) => {
	const initState = initialState; // reassign, so it can be appended, if needed

	// General form notification (ex. on success action)
	const [notification, setNotification] = useState(null);

	const fieldsRefs = useRef({});
	const formSettings = useRef(null);
	const formQuery = useRef(null);
	const Component = useRef(null);

	// Form component
	if (Component.current === null) {
		Component.current = AsyncComponent(() => {
			switch (name) {
			case 'calculator-init':				return import('../forms/calculator-init');
			case 'calculator-data':				return import('../forms/calculator-data');
			case 'calculator-contact':		return import('../forms/calculator-contact');
			default: return null;
			}
		});
	}

	// Form settings
	const loadFormSettings = () => {
		switch (name) {
		case 'calculator-init': 				return require('../../requests/mutation/calculator-init.js').default;
		case 'calculator-data': 				return require('../../requests/mutation/calculator-data.js').default;
		case 'calculator-contact': 			return require('../../requests/mutation/calculator-contact.js').default;
		default: return null;
		}
	};

	// Form query
	const loadFormQuery = () => {
		switch (name) {
		default: 												return require('../../requests/query/void.js').default;
		}
	};

	if (formSettings.current === null) {
		formSettings.current = loadFormSettings();
		formQuery.current = loadFormQuery();

		// Generate refs for all fields, which has validation (to manipulate after submit validation error - ex. scroll to field)
		if (formSettings.current && formSettings.current.validation) {
			Object.keys(formSettings.current.validation).map((fName) => {
				fieldsRefs.current[fName] = React.createRef();
				return true;
			});
		}
	}

	const getFormSettings = (section, key) => {
		if (formSettings.current) {
			const settings = formSettings.current[section] || formSettings.current;
			if (key) return settings[key] || null;
			return settings;
		}

		return null;
	};

	// Form submit handle
	const onSubmitForm = (state) => {
		if (disabled === false) {
			// Request to server only, if graphql scheme exist
			if (gqlMutation) {
				gqlMutation({
					variables: state,
					context: setContext(name)
				});
			} else {
				// If server request is not set, then just return data to parent
				handleOnSuccess(state);
			}
		} else {
			// Find first error and scroll to field + focus
			scrollToFirstError(state.firstErrorFieldName, fieldsRefs.current);
		}
	};

	const handleOnSuccess = (data) => {
		// Handle callback
		const onSuccessType = typeof onSuccess;

		switch (onSuccessType) {
		default: // function
			onSuccess(data);
			break;

		case 'object': {
			if (onSuccess.action) {
				switch (onSuccess.action) {

				case 'FORM_NOTIFICATION':
					console.log('Success!! Show form notification!');
					break;

				default:
					console.log('DO NOT KNOW, WHAT TO DO ;)');
					break;
				}
			}

			break;
		}
		}
	};

	// Load form data (init)
	const isQuery = (id && formQuery.current.request.name !== 'void');
	const query = formQuery.current;

	const validationRules = getFormSettings('validation');
	const gqlSchema = getFormSettings('gql', 'schema');

	// Init form handler
	const { state, handleFocus, handleOnChange, handleOnSubmit, validateForm, findFirstErrorFieldName, setError, updateState, updateChanged, disabled } = useForm(isQuery ? 'update' : 'insert', initState, validationRules, onSubmitForm);

	// Init mutation, if graphql scheme isset
	let gqlMutation = null;
	let loading = false;
	let data = null;
	let error = null;

	if (gqlSchema) {
		[gqlMutation, { loading, data, error }] = useMutation(gqlSchema);
	}

	// Load form data (run)
	const { loading: queryLoading, data: queryData, refetch } = useQuery(query.gql.schema, {
		variables: { id },
		context: { headers: { 'Request-Name': query.request.name } },
		skip: !isQuery,
		fetchPolicy: 'network-only' // no cache!
	});

	// In case of server submit - this side effect is triggered
	useEffect(() => {
		const err = gqlValidation(error);
		if (err) {
			scrollToFirstError(setError(err, true), fieldsRefs.current);
		} else if (onSuccess && data) {
			handleOnSuccess(data.request);
		}
		// eslint-disable-next-line
	}, [error, data]);

	// In case of query - prefill form, when data arrives
	useEffect(() => {
		if (isQuery) {
			refetch();

			if (queryLoading === false && queryData) {
				updateState(queryData.request);
			}
		}
	}, [id, queryData, queryLoading]);

	if (queryLoading === true) return 'Loading ...';

	return <Component.current input={{ state, placeholders, onChange: handleOnChange, onFocus: handleFocus, onBlur: handleFocus }} fieldsRefs={fieldsRefs.current || {}} handleOnSubmit={handleOnSubmit} validateForm={validateForm} findFirstErrorFieldName={findFirstErrorFieldName} disabled={disabled} loading={loading} notification={notification} updateChanged={updateChanged} updateState={updateState} />;
});

FormLoader.defaultProps = {
	id: null,
	initialState: {},
	placeholders: {},
	onSuccess: null
};

FormLoader.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	initialState: PropTypes.shape({}),
	placeholders: PropTypes.shape({}),
	onSuccess: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({})
	])
};

export default FormLoader;
