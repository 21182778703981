import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './calculator.module.scss';
import FormLoader from '../forms/loader';
import { useStore as calculatorStore } from '../../contexts/calculator';

const CalculatorSteps = ({ onMounted, stepWidth, onStepSuccess }) => {
	const { data: calculatorData } = calculatorStore();
	const { step, defaultValues } = calculatorData;

	useEffect(() => {
		onMounted();
	}, []);

	return (
		<div className={styles.steps} style={{ transform: `translate(-${(step - 1) * stepWidth}px , 0px)` }}>
			<div className={`${styles.step} ${styles[step === 1 ? 'visible' : 'hidden']}`}>
				<FormLoader name="calculator-init" initialState={{ amount: defaultValues.amount, repayment_period: defaultValues.repayment_period, bank: defaultValues.bank }} onSuccess={d => onStepSuccess(1, d)} />
			</div>

			<div className={`${styles.step} ${styles[step === 2 ? 'visible' : 'hidden']}`}>
				<FormLoader name="calculator-data" initialState={{ monthly_income: defaultValues.monthly_income, family: defaultValues.family }} onSuccess={d => onStepSuccess(2, d)} />
			</div>

			<div className={`${styles.step} ${styles[step === 3 ? 'visible' : 'hidden']}`}>
				<FormLoader name="calculator-contact" onSuccess={d => onStepSuccess(3, d)} />
			</div>
		</div>
	);
};

CalculatorSteps.propTypes = {
	onMounted: PropTypes.func.isRequired,
	onStepSuccess: PropTypes.func.isRequired,
	stepWidth: PropTypes.number.isRequired
};

export default CalculatorSteps;
