import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import styles from './blog.module.scss';
import { setContext } from '../../helpers/gql';
import request from '../../requests/query/blog/single';
import { lng } from '../../config/language';

// import banner1 from '../../assets/img/banner/mk-banner-poweredby.png';
// import banner2 from '../../assets/img/banner/nepnet-banner-poweredby.png';

const BlogPost = () => {
	const [post, setPost] = useState(null);

	const { loading, data } = useQuery(request.gql.schema, {
		context: setContext('blog>single')
	});

	useEffect(() => {
		if (loading === false && post === null && data && data.request.content_id) {
			setPost(data.request);
		}
	}, [loading, data]);

	if (!post) return null;
	/*
	<div className={`col-12 col-md-4 ${styles.sidebar}`}>
		<a href="https://mojikvadrati.com" target="_blank" rel="noopener noreferrer" className={styles.adPlaceholder}><img src={banner1} alt="Powered by Mojikvadrati.com" /></a>
		<a href="https://www.nepremicnine.net" target="_blank" rel="noopener noreferrer" className={styles.adPlaceholder}><img src={banner2} alt="Powered by Nepremicnine.net" /></a>
	</div>

	<time>{post.content_dtm}</time>  BEFORE <div className={styles.share}>
	*/
	return (
		<div className={styles.base}>
			<div className="container-fluid">
				<div className="row justify-content-md-center">
					<div className="col-12 col-md-8">
						<article className={styles.blogPost}>
							<em className={styles.tag}>{lng('tagPayedPost')}</em>
							<h1>{post.title}</h1>
							<div className={styles.more}>
								<div className={styles.share}>
									<a href="https://www.facebook.com/share/" target="_blank" rel="noopener noreferrer"><i className="icon link" /></a>
									<a href="https://www.twitter.com/share/" target="_blank" rel="noopener noreferrer"><i className="icon link" /></a>
									<a href="https://www.izracun-kredita.si/"><i className="icon link" /></a>
								</div>
							</div>
							<div className={styles.body} dangerouslySetInnerHTML={{ __html: post.text }} />
						</article>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogPost;
