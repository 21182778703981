import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout/layout';
import CalculatorLayout from '../components/calculator/calculator-layout';
import BlogPost from '../components/blog/post';
import Footer from '../components/footer/footer';
import styles from './index.module.scss';
import { CalculatorProvider } from '../contexts/calculator';

const Index = () => {
	return (
		<>
			<div className={styles.bgWrapper}><div className={styles.bg} /></div>
			<CalculatorProvider>
				<Layout>
					<SEO title="Dobrodošli" description="" />
					<CalculatorLayout />
					<BlogPost />
					<Footer />
				</Layout>
			</CalculatorProvider>
		</>
	);
};

export default Index;
