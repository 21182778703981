import React, { useState, useEffect } from 'react';
import useWindowScroll from '@react-hook/window-scroll';

import styles from './calculator.module.scss';
import { lng } from '../../config/language';
import Calculator from './calculator';
import CalculatorNotification from './calculator-notification';
import CalculatorBottom from './calculator-bottom';
import { useStore as calculatorStore } from '../../contexts/calculator';
import { testMobile } from '../../helpers/common';

const CalculatorLayout = () => {
	const [scroll, setScroll] = useState(0);
	const { data: calculatorData } = calculatorStore();
	const { notification, modal: { visible } } = calculatorData; // visible = CalculatorModalDecision modal is visible on last step
	const [isMobile] = useState(testMobile());
	const scrollY = useWindowScroll(60);

	useEffect(() => {
		// REŠI PREKO CONTEXT!!
		if (window && isMobile) {
			document.addEventListener('scroll', () => {
				const scrollCheck = window.scrollY > 350;
				if (scrollCheck !== scroll) setScroll(scrollCheck);
			});
		}
	});

	return (
		<>
			<CalculatorNotification variation="phone" />
			{!visible && <CalculatorBottom scrollY={scrollY} variation="phone" />}
			<div className={`container-fluid phone ${(notification.visible) ? styles.appHidden : ''}`} style={{ position: 'relative', zIndex: 2 }}>
				<div className="row">
					<div className={`d-sm-flex col-12 col-lg-6 ${styles.hero} ${scroll ? 'onScrollHide' : 'onScrollShow'}`}>
						<h1>{lng('heroTitle')}</h1>
						<p>{lng('heroDescription')}</p>
					</div>
					<div className={`col-12 col-lg-6 ${styles.calculator}`}>
						<Calculator />
					</div>
				</div>
			</div>
		</>
	);
};

export default CalculatorLayout;
