import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { formatPrice } from '../../helpers/number';
import styles from './calculator.module.scss';
import { lng } from '../../config/language';
import Button from '../form/button';
import { useStore as calculatorStore } from '../../contexts/calculator';
import useCalculator from '../_hooks/useCalculator';
import useGa from '../_hooks/useGa';

const CalculatorModalDecision = React.memo(({ onStepSuccess }) => {
	const { sendEvent } = useGa();
	const { isBSCandidate } = useCalculator();
	const { data: calculatorData, dispatch } = calculatorStore();
	const { data, modal: { visible, onConfirm, onCancel } } = calculatorData;

	const [recalculated, setRecalculated] = useState({});
	const [changed, setChanged] = useState({});

	const [isVisible, setIsVisible] = useState(false);

	/*
	<button type="button" onClick={() => close()}>x</button>
	const close = () => {
		sendEvent(null, null, 'BS DECISION MODAL: closed');

		// Hide modal
		dispatch({
			type: 'MODAL',
			payload: { visible: false, data: {} }
		});
	};
	*/

	const onSubmit = (type) => {
		sendEvent(null, null, `BS DECISION MODAL: ${type}`);

		// Hide modal
		dispatch({
			type: 'MODAL',
			payload: { visible: false, data: {} }
		});

		// Changed data or not
		const d = data;

		if (type === 'changed') {
			d.is_candidate_bs = 'da';

			if (recalculated.repayment_period_changed) {
				d.repayment_period = recalculated.repayment_period_changed;
			}

			if (recalculated.amount_changed) {
				d.amount = recalculated.amount_changed;
			}
		}

		if (onConfirm) onConfirm(d);
		else console.log(d);
	};

	useEffect(() => {
		if (visible) {
			sendEvent(null, null, `BS DECISION MODAL: open`);

			const repaymentPeriodMax = 30;

			// Change
			const r = {
				repayment_period: data.repayment_period,
				repayment_period_changed: null,
				amount: data.amount,
				// amount: 50000,
				amount_changed: null
			};

			// Handled
			const h = {
				repayment_period: false,
				amount: false
			};

			if (r.repayment_period && r.repayment_period < repaymentPeriodMax) {
				let repaymentPeriod = r.repayment_period;
				[...Array(repaymentPeriodMax - r.repayment_period)].map((elementInArray, i) => {
					if (!r.repayment_period) {
						repaymentPeriod += 1;
						if (isBSCandidate({ ...data, repayment_period: repaymentPeriod })) {
							r.repayment_period_changed = repaymentPeriod;
							h.repayment_period = true;
						}
					}

					return true;
				});
			}

			// Try to dec amount, to become compliant with BS (if handle repayment period was not enought)
			if (h.repayment_period === false) {
				r.repayment_period_changed = repaymentPeriodMax; // take max. repayment method and try to lower price

				[...Array(r.amount / 1000)].map((elementInArray, i) => {
					if (!r.amount_changed) {
						const a = r.amount - (i * 1000);
						if (isBSCandidate({ ...data, amount: a })) {
							r.amount_changed = r.amount - (i * 1000);
						}
					}

					return true;
				});
			}

			// Not canddiate at all!
			if (!r.repayment_period_changed && !r.amount_changed && onCancel) {
				onCancel();

				sendEvent(null, null, 'BS DECISION MODAL: unable to modify');

				// Hide modal
				dispatch({
					type: 'MODAL',
					payload: { visible: false, data: {} }
				});
			} else { // Have new data - open/show popup!
				setIsVisible(true);
				setRecalculated(r);
			}
		}
	}, [visible]);

	useEffect(() => {
		setChanged({
			repayment_period: (recalculated.repayment_period_changed && recalculated.repayment_period !== recalculated.repayment_period_changed),
			amount: (recalculated.amount_changed && recalculated.amount !== recalculated.amount_changed)
		});
	}, [recalculated]);

	return (
		<div className={`${styles.modal} ${styles[isVisible ? 'visible' : 'hidden']}`}>
			<div className={styles.align}>
				<header>
					<h2>{lng('modalBSModifyTitle')}<span className={styles.loading} /></h2>
					<h3>{lng('modalBSModifySubtitle')}</h3>
					<p>{lng('modalBSModifyText')}</p>
				</header>

				<div className={styles.boxes}>
					<div className={changed.amount ? styles.success : ''}>
						<span>{lng('modalBSModifyBoxAmountTitle')}</span>
						<strong>{formatPrice(recalculated.amount_changed || data.amount)} €</strong>
						{changed.amount && <p>{lng('modalBSModifyBoxAmountDisclaimer')}: {formatPrice(data.amount)} €</p>}
					</div>
					<div className={changed.repayment_period ? styles.success : ''}>
						<span>{lng('modalBSModifyBoxPeriodTitle')}</span>
						<strong>{recalculated.repayment_period_changed || data.repayment_period} let</strong>
						{changed.repayment_period && <p>{lng('modalBSModifyBoxPeriodDisclaimer')}: {data.repayment_period} let</p>}
					</div>
				</div>

				<footer>
					<Button ariaLabel="ne, pošlji to, kar sem nastavil" classes="text" onClick={() => onSubmit('unchanged')}>{lng('modalBSModifyBtnCancel')}</Button>
					<Button ariaLabel="da, sprejmem predlog" classes="primary lg" onClick={() => onSubmit('changed')}>{lng('modalBSModifyBtnOk')}</Button>
				</footer>
			</div>
		</div>
	);
});

CalculatorModalDecision.propTypes = {
	onStepSuccess: PropTypes.func.isRequired
};

export default CalculatorModalDecision;
