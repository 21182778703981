export function gqlError(error) {
	return (error && error.graphQLErrors[0].potError) ? error.graphQLErrors[0].potError : false;
}

export function gqlValidation(error) {
	const err = gqlError(error);

	if (err && err.errorData) {
		return err.errorData.validation;
	}

	return null;
}

export const gqlData = (data) => {
	return data || false;
};

export const setHeaders = (requestName, headers = {}) => {
	return { 'Request-Name': requestName, ...headers };
};

export const setContext = (requestName, context = {}) => {
	return { headers: setHeaders(requestName), ...context };
};
