import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

import reducer from '../reducers/calculator';

const CalculatorContext = createContext();
export default CalculatorContext;

export function CalculatorProvider({ children }) {
	const location = useLocation();

	// Check, if any parameters are predefined via url
	const paramsViaURL = location.search ? queryString.parse(location.search) : {};

	const [data, dispatch] = useReducer(reducer, {
		instanceId: 1, // to reset calculator component
		monthlyInstallment: 0,
		data: {},
		steps: [1, 2, 3],
		step: 1,
		submitBtn: {},
		submitCnt: 0,
		submittedStep: null,
		submitLocked: false,
		paramsViaURL: Object.keys(paramsViaURL).length > 0 ? paramsViaURL : null,
		defaultValues: {
			amount: paramsViaURL.amount || 50000,
			repayment_period: paramsViaURL.repayment_period || 20,
			bank: paramsViaURL.bank ? paramsViaURL.bank.split(',') : [],
			monthly_income: 1500,
			family: 'Brez vzdrževanih članov'
		},
		notification: {
			visible: false // check "component/calculator-data.js" how object should look like
		},
		modal: {
			visible: false,
			data: {},
			onConfirm: null,
			onCancel: null
		}
	});

	return (<CalculatorContext.Provider value={{ data, dispatch }}>{children}</CalculatorContext.Provider>);
}

CalculatorProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export const useStore = () => useContext(CalculatorContext);
