import React from 'react';
import PropTypes from 'prop-types';

import notificationOk from '../../assets/img/notification-ok.png';
import notificationError from '../../assets/img/notification-error.png';
import styles from './calculator.module.scss';
import { lng } from '../../config/language';
import Button from '../form/button';
import { useStore as calculatorStore } from '../../contexts/calculator';

const CalculatorNotification = React.memo(({ variation }) => {
	const { data: calculatorData, dispatch } = calculatorStore();
	const { data, notification } = calculatorData;
	const { visible } = notification;

	return (
		<div className={`${styles.notification} ${styles[visible ? 'visible' : 'hidden']} ${styles[variation]}`}>
			<img src={notification.type === 'ok' ? notificationOk : notificationError} alt="notification" />
			{notification.title && <h3>{notification.title}</h3>}
			{notification.text && <div dangerouslySetInnerHTML={{ __html: notification.text }} className={styles.desc} />}
			{notification.textAdditional && <p dangerouslySetInnerHTML={{ __html: notification.textAdditional }} className={[styles.lighter, styles.desc].join(' ')} />}
			{notification.showData ? (
				<table cellSpacing="0" cellPadding="0" border="0">
					<tbody>
						<tr>
							<td>{lng('labelName')}</td>
							<td>{data.givenname} {data.surname}</td>
						</tr>
						<tr>
							<td>{lng('labelEmail')}</td>
							<td>{data.email}</td>
						</tr>
						<tr>
							<td>{lng('labelPhone')}</td>
							<td>{data.telephone}</td>
						</tr>
					</tbody>
				</table>
			) : null}
			{notification.redoBtn && <Button ariaLabel="reset in nazaj na začetek" classes={['primary', notification.redoBtn.icon ? 'icon left' : 'default'].join(' ')} onClick={() => dispatch({ type: 'RESET_CALCULATOR' })}>{notification.redoBtn.text} {notification.redoBtn.icon ? <i className={`icon ${notification.redoBtn.icon}`} /> : null}</Button>}
		</div>
	);
});

CalculatorNotification.propTypes = {
	variation: PropTypes.string.isRequired
};

export default CalculatorNotification;
