import { useMutation } from '@apollo/react-hooks';

import { useStore as calculatorStore } from '../../contexts/calculator';
import { setContext } from '../../helpers/gql';
import requestCatchRequest from '../../requests/mutation/calculator-catch-request';

const useCalculator = () => {
	const [gqlRequestCatchMutation] = useMutation(requestCatchRequest.gql.schema);

	const { data: calculatorData } = calculatorStore();
	const { data } = calculatorData;

	const catchRequest = (variables) => {
		gqlRequestCatchMutation({
			variables, // combine all saved data till now and from last form
			context: setContext('catch_request'), // use same "request" as submit, but another request name (same structure of data)
			errorPolicy: 'all'
		});
	};

	const pmt = (ratePerPeriod, numberOfPayments, presentValue) => {
		const futureValue = 0;
		const type = 0;

		if (ratePerPeriod !== 0.0) {
			const q = (1 + ratePerPeriod) ** numberOfPayments; // new version Math.pow(1 + ratePerPeriod, numberOfPayments)
			return -(ratePerPeriod * (futureValue + (q * presentValue))) / ((-1 + q) * (1 + ratePerPeriod * (type)));
		}

		return 0;
	};

	const prepareData = (d) => {
		const r = {};
		const list = d ? Object.keys(d) : [];

		if (list.length > 0) {
			list.map((k) => {
				r[k] = (typeof d[k] === 'object' && d[k]) ? (d[k].value || null) : d[k];
				return true;
			});
		}

		return r;
	};

	const calculateMonthlyInstallment = (remoteData) => {
		const d = prepareData(remoteData) || data;
		const interestRate = 3.8; // %

		return Math.round((Math.abs(pmt((interestRate / 100) / 12, d.repayment_period * 12, d.amount))) * 100) / 100;
	};

	const isBSCandidate = (remoteData, returnResult = false) => {
		const d = remoteData ? { ...data, ...prepareData(remoteData) } : data;

		if (d.monthly_income) {
			const isF = (d.children_cnt > 0 || d.children_cnt === 'Več' || d.dependents > 0 || d.dependents === 'Več');
			const minWage = 745;

			const family = {
				Enostarševska: {
					factor: 1,
					zNo: 274
				},
				Dvostarševska: {
					factor: 2,
					zNo: 274
				}
			};

			const adultCnt = d.dependents !== 'Več' ? Number(d.dependents) : 5;
			const childrenCnt = d.children_cnt !== 'Več' ? Number(d.children_cnt) : 5;
			const byFamily = family[d.family] || {};

			const familyRelatedPart = isF ? ((childrenCnt + adultCnt) / byFamily.factor) * byFamily.zNo : 0;

			// (PRIHODEK - (MP + (Št.Otrok + Št.Odraslih)/(factor * ZnO))
			// setStepAmount(d.monthly_income - (minWage + ((childrenCnt + adultCnt) / byFamily.factor) * byFamily.zNo));
			const amount = d.monthly_income - (minWage + familyRelatedPart);
			const monthlyInstallment = calculateMonthlyInstallment(d);

			// console.log(monthlyInstallment, amount, monthlyInstallment <= amount);

			const status = monthlyInstallment <= amount;

			// Optional: return calculated values + status as result
			if (returnResult === true) {
				return { status, monthlyInstallment, amount };
			}

			// Default = just status
			return status;
		}

		return false;
	};

	return { calculateMonthlyInstallment, isBSCandidate, prepareData, catchRequest };
};

export default useCalculator;
