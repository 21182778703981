import React from 'react';

import styles from './footer.module.scss';
import logoMojikvadrati from '../../assets/img/powered-by-mk.svg';
import { lng } from '../../config/language';

const Footer = () => {
	return (
		<>
			<div className={styles.disclaimer}>
				<div className="container-fluid">
					<div className="row justify-content-md-center">
						<div className="col-12 col-md-8">
							<article>{lng('footerDisclaimer')}</article>
						</div>
					</div>
				</div>
			</div>
			<footer className={styles.base}>
				<a href="https://mojikvadrati.com/" target="_blank" rel="noreferrer noopener"><img src={logoMojikvadrati} alt="Mojikvadrati.com logo" title="Mojikvadrati.com" /></a>
				<div className={styles.legal}>
					<a href="https://www.izracun-kredita.si/files/splosni-pogoji.pdf" target="_blank" rel="noreferrer noopener">{lng('labelLink1')}</a>
					<a href="https://www.izracun-kredita.si/files/politika-varstva-osebnih-podatkov.pdf" target="_blank" rel="noreferrer noopener">{lng('labelLink2')}</a>
					<a href="https://www.izracun-kredita.si/files/piskotki.pdf" target="_blank" rel="noreferrer noopener">{lng('labelLink3')}</a>
				</div>
			</footer>
		</>
	);
};

export default Footer;
