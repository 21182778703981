import { useState, useEffect, useCallback } from 'react';

import { lng } from '../../config/language';

function useForm(action = 'insert', formState = {}, formSchema = {}, callback) {
	// Create initial state of form
	const initialState = {};

	if (formSchema) {
		Object.keys(formSchema).map((fName) => {
			let defaultValue = '';

			// Change default value from empty string to array
			if (formSchema[fName].collector === true) {
				defaultValue = [];
			}

			let fieldValue = formState[fName] || defaultValue;
			if (fieldValue && typeof fieldValue === 'object' && Array.isArray(fieldValue)) {
				fieldValue = [...fieldValue]; // Create totaly unlinked copy of array with its parent!!!! Default by reference!!
			}

			initialState[fName] = { value: fieldValue, error: null };
			return true;
		});
	}

	const [state, setState] = useState(initialState);
	const [disabled, setDisabled] = useState(true);
	const [changed, setChanged] = useState(false);

	// Used to disable submit button if there's an error in state
	// or the required field in state has no value.
	// Wrapped in useCallback to cached the function to avoid intensive memory leaked
	// in every re-render in component
	const validateState = useCallback(() => {
		const hasErrorInState = Object.keys(formSchema).some((key) => {
			const isInputFieldRequired = formSchema[key].required;
			let stateValue = state[key].value || ''; // state value
			const stateError = state[key].error || ''; // state error

			// Handle required in case of collector (array of values)
			if (formSchema[key].collector === true) {
				stateValue = (state[key].value.length > 0);
			}

			return (isInputFieldRequired && !stateValue) || stateError;
		});

		return hasErrorInState; // if return TRUE, then form is not valid - if return FALSE, then form is valid
	}, [state, formSchema]);

	// Validate complete for, to get all errors (in client - when client is valid, request go to server and second layer of validation is triggered)
	const validateForm = (applyErrorsToState = false) => {
		const errors = {};
		let isValid = true;

		Object.keys(formSchema).map((key) => {
			const validation = validateField(key, state[key].value || '');
			errors[key] = validation.error || null;

			if (isValid === true && errors[key] !== null) {
				isValid = false;
			}

			return true;
		});

		if (applyErrorsToState === true) {
			setError(errors);
		}

		return isValid ? true : errors;
	};

	const validateField = (name, value) => {
		let v = value;

		if (formSchema[name]) {
			const { required, validator } = formSchema[name];

			// Handle required in case of collector (array of values)
			if (formSchema[name].collector === true) {
				v = (state[name].value.length > 0);
			}

			let errorMessage = '';
			if (required) {
				if (!v) {
					errorMessage = lng('validationRequired');
				}
			}

			if (validator !== null && typeof validator === 'object') {
				if (value && !validator.regEx.test(v)) {
					const { error } = validator;
					errorMessage = error;
				}
			}

			return {
				value: v,
				error: errorMessage || null
			};
		}

		return {
			value: v,
			error: null
		};
	};

	// Used to handle every changes in every input
	const handleOnChange = useCallback((e, options = {}) => {
		const { target } = e;
		const { name, value, type, checked } = target;

		const isCollector = (options.collector === true); // ex checkbox group for same name (bank: [nkbm, nlb])
		let newValue = (type === 'checkbox' && !isCollector) ? checked : value;

		// Handle string values "true/false" as boolena (case: radio buttons)
		if (newValue && typeof newValue === 'string') {
			if (newValue.toLowerCase() === 'true') newValue = true;
			else if (newValue.toLowerCase() === 'false') newValue = false;
		}

		// Ex. array of checkboxes with same name
		if (isCollector) {
			const v = newValue;
			newValue = (Array.isArray(state[name].value)) ? state[name].value : [];

			if (checked) {
				newValue.push(v);
			} else {
				newValue.splice(newValue.indexOf(v), 1);
			}
		}

		const validation = validateField(name, newValue);

		setState(prevState => (
			{ ...prevState, [name]: { value: newValue, error: validation.error } }
		));

		if (changed === false) {
			setChanged(true);
		}
	// eslint-disable-next-line
	}, []);

	const findFirstErrorFieldName = (errors) => { // errors:{key:value} => fname => error message/null
		const fieldName = Object.keys(errors).filter(key => errors[key] !== null);
		return fieldName[0] || null;
	};

	const handleOnSubmit = useCallback((e, additionalStateData) => {
		if (e && typeof e.preventDefault === 'function') {
			e.preventDefault();
		}

		let resultState = {};
		const errors = validateForm();

		if (typeof errors === 'object') {
			setError(errors);
			resultState = {
				firstErrorFieldName: findFirstErrorFieldName(errors),
				state,
				errors
			};
		} else {
			resultState = {};
			// Clear state, to return only field_name:value pairs
			Object.keys(state).map((fName) => {
				resultState[fName] = state[fName].value || null;
				return true;
			});

			if (additionalStateData) {
				Object.keys(additionalStateData).map((fName) => {
					resultState[fName] = additionalStateData[fName];
					return true;
				});
			}
		}

		callback(resultState);

	// eslint-disable-next-line
	}, [state, callback, validateState]);

	const setError = useCallback((errors, returnFirst = false) => {
		Object.keys(errors).map((fName) => {
			setState(prevState => (
				{ ...prevState, [fName]: { value: prevState[fName].value, error: errors[fName] } }
			));
			return true;
		});

		if (returnFirst === true) {
			return findFirstErrorFieldName(errors);
		}

		return true;
	// eslint-disable-next-line
	}, [state]);

	const updateChanged = useCallback((status) => {
		if (changed !== status) {
			setChanged(status);

			// Enable, if form is valid and remote changed is triggered
			if (status === true) {
				const requestValidation = validateState();
				if (requestValidation !== disabled) {
					setDisabled(requestValidation);
				}
			}
		}
	});

	const handleFocus = useCallback((e) => {
		const { target, type } = e;
		const { name } = target;

		setState(prevState => (
			{ ...prevState, [name]: { ...prevState[name], focus: type === 'focus' } }
		));
	});

	const updateState = useCallback((data) => {
		Object.keys(data).map((fName) => {
			let modifiedData = data[fName] || ''; // value must be empty string, not null! (in case, if not exist)

			// If array, check if children nodes does not have key "__typename"
			if (Array.isArray(data[fName])) {
				modifiedData = [];

				data[fName].map((row) => {
					if (row['__typename']) {
						delete row['__typename'];
					}

					modifiedData.push(row);
					return true;
				});
			}

			setState(prevState => (
				{ ...prevState, [fName]: { value: modifiedData || '', error: null } }
			));
			return true;
		});

		return true;
	// eslint-disable-next-line
	}, [state]);

	// For every changed in our state this will be fired
	// To be able to disable the button
	useEffect(() => {
		if (changed === true) {
			const requestValidation = validateState();
			// console.log('STATE HAS ERRORS', requestValidation, 'DISABLED', disabled);
			if (requestValidation !== disabled) { // ex. disabled = true & validationResult = false \ DO NOTHING, ok!
				setDisabled(requestValidation);
			}
		}
	}, [state, disabled, validateState]);

	// On mount - if valid (on update), disabled = false
	useEffect(() => {
		if (action === 'update' && validateState()) {
			setDisabled(false);
		}
	}, []);

	return { state, disabled, handleFocus, handleOnChange, handleOnSubmit, validateForm, findFirstErrorFieldName, setError, updateState, updateChanged };
}

export default useForm;
