// import gql from 'graphql-tag';

const request = {
	validation: {
		amount: {
			required: true,
			validator: null
		},

		repayment_period: {
			required: true,
			validator: null
		},

		bank: {
			required: true,
			validator: null,
			collector: true // array of checkbox values
		}
	},

	gql: null
};

export default request;
