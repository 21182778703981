import gql from 'graphql-tag';

const request = {
	validation: null,

	request: {
		name: 'blog>single'
	},

	gql: {
		schema: gql`
			query BlogPost {
			  request {
					content_id
			    title
			    text
			    content_dtm
			  }
			}
		`
	}
};

export default request;
