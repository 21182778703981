function useGa() {
	const getTrackingId = () => {
		return 'UA-109126882-11';
	};

	const isReady = () => {
		return (typeof window !== 'undefined' && typeof window.gtag !== 'undefined');
	};

	const sendPageView = (page) => {
		if (isReady()) {
			// https://developers.google.com/gtagjs/reference/api#event
			window.gtag('config', getTrackingId(), {
				// hitType: 'pageview',
				page_location: page || 1
			});

			// Debug
			console.log('PageView sent!', page || 1);
		}
	};

	const sendEvent = (category, action, label, value) => {
		if (isReady()) {
			const options = {
				eventCategory: (category !== undefined && category !== null) ? category : 'Button',
				eventAction: (action !== undefined && action !== null) ? action : 'click',
				eventLabel: (label !== undefined && label !== null) ? label : '',
				eventValue: (value !== undefined && value !== null) ? value : ''
			};

			// https://developers.google.com/analytics/devguides/collection/gtagjs/events
			window.gtag('event', options.eventAction, {
				event_category: options.eventCategory,
				event_label: options.eventLabel,
				value: options.eventValue
			});

			// Debug
			console.log(options.eventCategory, options.eventAction, options.eventLabel, options.eventValue);
		}
	};

	return { sendPageView, sendEvent };
}

export default useGa;
