import gql from 'graphql-tag';

const request = {
	validation: {},

	gql: {
		// Any change here, update calculator-submit.js
		schema: gql`
			mutation CatchRequest(
				$source: String,
				$request_id: String,
				$amount: Int,
				$repayment_period: Int,
				$bank: [String],
				$monthly_income: Int,
				$children_cnt: String,
				$dependents: String,
				$family: String,
				$credit_time: String,
				$givenname: String,
				$surname: String,
				$email: String,
				$telephone: String,
				$is_candidate_bs: String
			) {
				request(
					source: $source,
					request_id: $request_id,
					amount: $amount,
					repayment_period: $repayment_period,
					bank: $bank,
					monthly_income: $monthly_income,
					children_cnt: $children_cnt,
					dependents: $dependents,
					family: $family,
					credit_time: $credit_time,
					givenname: $givenname,
					surname: $surname,
					email: $email,
					telephone: $telephone,
					is_candidate_bs: $is_candidate_bs
				) {
					id
				}
			}
		`
	}
};

export default request;
