export const scrollToFirstError = (fieldName, refs) => {
	if (fieldName && refs[fieldName]) {
		const target = refs[fieldName].current;

		if (target) {
			target.focus();
			window.scrollTo({ top: ((target.getBoundingClientRect().top + window.pageYOffset) - 50), behavior: 'smooth' });
		}
	}
};

export const test = () => {};
