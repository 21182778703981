import { lng } from '../config/language';

const notificationDefault = { visible: false, type: 'error' };
const submitBtnDefault = { text: lng('btnGetBankOffer'), icon: null };

const reducer = (state, action) => {
	switch (action.type) {
	// == Remote submit & submit button

	case 'RESET_SUBMIT': {
		return { ...state, submittedStep: null };
	}

	case 'SUBMIT_STEP': {
		return { ...state, submittedStep: state.step, submitCnt: state.submitCnt + 1 };
	}

	case 'SET_SUBMIT_BUTTON': {
		const { payload } = action;
		return { ...state, submitBtn: payload };
	}

	case 'RESET_SUBMIT_BUTTON': {
		return { ...state, submitBtn: submitBtnDefault };
	}

	case 'TOGGLE_SUBMIT_LOCK': {
		return { ...state, submitLocked: !state.submitLocked };
	}

	// == Data

	case 'UPDATE_DATA': {
		const { payload } = action;
		const { data } = payload;
		return { ...state, data: { ...state.data, ...data } };
	}

	// == Moving/reset calculator

	case 'RESET_CALCULATOR': {
		return { ...state, step: 1, submittedStep: null, instanceId: state.instanceId + 1, data: state.defaultValues, notification: notificationDefault, submitBtn: submitBtnDefault, submitLocked: false };
	}

	case 'STEP_PREV': {
		const { step } = state;
		return { ...state, step: step - 1 };
	}

	case 'STEP_NEXT': {
		const { step } = state;
		return { ...state, step: step + 1 };
	}

	case 'SET_MONTHLY_INSTALLMENT': {
		const { payload } = action;
		return { ...state, monthlyInstallment: payload };
	}

	// == Notification

	case 'SET_NOTIFICATION': {
		const { payload } = action;
		return { ...state, notification: payload };
	}

	case 'RESET_NOTIFICATION': {
		return { ...state, notification: notificationDefault };
	}

	// == Modal

	case 'MODAL': {
		const { payload: { visible, data, onConfirm, onCancel } } = action;
		return { ...state, modal: { visible: visible || false || false, data: data || {}, onConfirm, onCancel } };
	}

	default:
		return state;
	}
};

export default reducer;
