import { useState } from 'react';

const useAnalytics = () => {
	const [debug] = useState(false);

	// Facebook Pixel
	// https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking
	// https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
	const fbEvent = (name, props) => {
		if (typeof window !== 'undefined' && name) {
			if (window.fbq != null) {
				switch (name) {
				// Standard events
				default:
				case 'Purchase':
				case 'AddToCart':
				case 'InitiateCheckout':
				case 'PageView':
				case 'Subscribe':
				case 'Lead':
					window.fbq('track', name, props);
					break;

				// Custom events (not exist yet)
				case 'AddToCartFirst':
				case 'ClearCart':
					window.fbq('trackCustom', name, props);
					break;
				}
			} else {
				console.log('window.fbq is not defined.');
			}
		}
	};

	// Google Analytics
	const isReady = () => (typeof window !== 'undefined' && typeof window.gtag !== 'undefined');

	// https://developers.google.com/analytics/devguides/collection/gtagjs/events
	const gaEvent = (name, props) => {
		if (isReady()) {
			const action = props.eventAction || 'click';
			const obj = {
				event_category: props.eventCategory || 'ecommerce',
				event_label: name,
				value: props.value // string!
			};

			window.gtag('event', action, obj);

			// Debug
			if (debug) console.log(name, action, obj);
		}
	};

	return { fbEvent, gaEvent };
};

export default useAnalytics;
