// import gql from 'graphql-tag';

const request = {
	validation: {
		monthly_income: {
			required: true,
			validator: null
		},

		children_cnt: {
			required: true,
			validator: null
		},

		dependents: {
			required: true,
			validator: null
		},

		family: {
			required: true,
			validator: null
		},

		credit_time: {
			required: true,
			validator: null
		},

		is_candidate_bs: {
			required: false,
			validator: null
		}
	},

	gql: null
};

export default request;
